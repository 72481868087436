import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

import { ISeo } from "gatsby-theme-gaviscon/src/components/common/Seo/models"

import { preloadFontLinks } from "gatsby-theme-gaviscon/src/components/common/Seo/preload"

const Seo: FC<ISeo> = ({
  siteSettings,
  brandSettings,
  description,
  lang,
  keywords,
  title,
  imageUrl,
  imageAlt,
  externalHreflangs,
  openGraphImageUrl,
}) => {
  const location = useLocation()

  const siteTitle = siteSettings?.siteName || ""
  const titleTemplateSeparator = siteSettings?.titleTemplateSeparator || "|"

  let titleTemplate = ""

  if (title === siteTitle) {
    titleTemplate = "%s"
  } else if (siteSettings?.titleTemplateDismissSiteTitle) {
    titleTemplate = "%s"
  } else {
    titleTemplate = `%s ${titleTemplateSeparator} ${siteTitle}`
  }

  const siteAuthor = brandSettings.brandName
  const siteLang = lang || siteSettings?.siteLang || siteSettings?.lang
  const direction = siteSettings?.isRTL === "1" ? "rtl" : "ltr"
  const ogImage =
    openGraphImageUrl ||
    imageUrl ||
    externalHreflangs?.find(item => item.key === "og:image")?.value
  const externalAlternateLinks = externalHreflangs?.length
    ? externalHreflangs
        ?.filter(item => item.key !== "og:image")
        ?.map(item => ({
          rel: "alternate",
          href: item.value,
          hrefLang: item.key,
        }))
    : []
  const link = [...externalAlternateLinks, ...preloadFontLinks]
  const meta = [
    {
      name: "robots",
      content:
        process.env.GATSBY_ACTIVE_ENV === "production"
          ? "index, follow"
          : "noindex, nofollow",
    },
    {
      name: "description",
      content: description,
    },
    {
      property: "og:url",
      content: location?.origin + location?.pathname,
    },
    {
      property: "og:title",
      content: title,
    },
    ...(ogImage
      ? [
          {
            property: "og:image",
            content: ogImage,
          },
        ]
      : []),
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: siteAuthor,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:image:src",
      content: imageUrl || "",
    },
    {
      name: "twitter:image:alt",
      content: imageAlt || "",
    },
    ...(keywords?.length
      ? [
          {
            name: "keywords",
            content: keywords.join(", "),
          },
        ]
      : []),
  ]

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang, dir: direction }}
      {...{ title, titleTemplate, meta, link }}
    />
  )
}

export default Seo
